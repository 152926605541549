.content-block {
    background-attachment: fixed;
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}



.content-container {
    width: 80%;
    margin: 0 auto;
}
.mb60{
  margin-bottom: 4rem;
}
.mt60{
  margin-top: 4rem;
}


.ImageListItemBar b{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 20px 0px 0px 0px;
}

#section-id-1576804020298 {
    padding-top: 225px;
    padding-right: 0px;
    padding-bottom: 8px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 0 0;
}

#sp-page-builder .sppb-section, #sp-page-builder .sppb-section {
    will-change: top;
}
#sp-page-builder  .sppb-section, #sp-page-builder .sppb-section, .mod-sppagebuilder .sppb-section {
    z-index: 1;
    position: relative;
}
.sppb-shape-container.sppb-top-shape {
    top: -1px;
}
.sppb-shape-container {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    z-index: 2;
}
#section-id-1576804020298 .sppb-shape-container.sppb-top-shape > svg {
    width: 100%;
    max-width: 100%;
}
.sppb-shape-container svg {
    display: block;
    width: 100%;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
#section-id-1576804020298 .sppb-shape-container.sppb-top-shape > svg path, #section-id-1576804020298 .sppb-shape-container.sppb-top-shape > svg polygon {
    fill: #000000;
}
.sppb-shape-container.sppb-bottom-shape {
    bottom: -1px;
}
.sppb-shape-container {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    z-index: 2;
}
.sp-page-builder .page-content #section-id-1576804020298 > .sppb-row-overlay {
    mix-blend-mode: normal;
}
.sp-page-builder .page-content #section-id-1576804020298 > .sppb-row-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}
/* .sppb-row-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: repeat;
    z-index: 1;
} */
.sppb-row-container {
    max-width: 90%;
    margin: 0 auto;
}
.sp-pagebuilder-row, .sppb-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.sppb-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
#column-id-1576804020328 {
    box-shadow: 0 0 0 0 #fff;
}
.sppb-column {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.sppb-column, .sppb-column-addons {
    position: relative;
    z-index: 1;
    width: 100%;
}
.sppb-column, .sppb-column-addons {
    position: relative;
    z-index: 1;
    width: 100%;
}
#sppb-addon-wrapper-1584686635803 {
    margin: 10px 5% 0px 5%;
}
#sppb-addon-1584686635803 {
    font-size: 23px;
    line-height: 29px;
}
#sppb-addon-1584686635803 {
    color: #ffffff;
    box-shadow: 0 0 0 0 #ffffff;
}
.sppb-text-center {
    text-align: center;
}
.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
#sppb-addon-1584686635803 .sppb-addon-title {
    font-family: "Roboto";
}
#sppb-addon-1584686635803 .sppb-addon-title {
    color: #ffffff;
    font-size: 55px;
    font-weight: 700;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
#sppb-addon-wrapper-1576804020331 {
    margin: 0px 0px 110px 0px;
}
.sppb-addon-single-image-container {
    display: inline-block;
    position: relative;
    transition: all .4s ease;
}
.sppb-text-center .sppb-img-responsive, .sppb-text-left .sppb-img-responsive, .sppb-text-right .sppb-img-responsive {
    display: inline-block;
}
.sppb-carousel-inner>.sppb-item>a>img, .sppb-carousel-inner>.sppb-item>img, .sppb-img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}








.container {
    width: 95%;
}

.block.block-nfts-across-uk {
    padding: 2rem 0 2rem 0;
}
.block {
    position: relative;
    --content-color: #000000;
    padding: 3rem 0;
    color: #000;
}
.container {
    margin: 0 auto;
    padding: 0 00px;
}

[class*="col-"] {
    padding: 20px;
}
.col-sm-4 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 1rem;
}
.block.block-nfts-across-uk .title {
    font-size: 50px;
    line-height: 1;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: 800;
    line-height: 1.4;
    word-break: break-word;
    color:#fff;
    text-transform: uppercase;
    letter-spacing: 0px;
    text-shadow: 0 0 BLACK;
    display:contents;
}
.col-sm-8 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 1rem;
}
.block.block-nfts-across-uk .field--name-field-hubs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.block.block-nfts-across-uk .field--name-field-hubs .field__item {
    width: 33%;
    padding: 8px;
    height: 16rem;
}
.teaser.node--teaser-alt {
    position: relative;
    height: 100%;
    width: 100%;
    max-height: 100%;
    transition: border 0.3s ease-in-out;
    display: block;
    z-index: 9;
}
.teaser.node--teaser-alt .teaser__img {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
}
.teaser.node--teaser-alt .teaser__img:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../assets/img/A21.jpeg);
    background-size: cover;
    transition: all 0.3s ease-in-out;
    background-position: bottom;
}
.teaser.node--teaser-alt .teaser__img:after {
    content: '';
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    opacity: 1;
    background: linear-gradient(180deg,transparent 0%,#000000 100%);
}
.teaser.node--teaser-alt .teaser__img .teaser--meta {
    position: relative;
}


.teaser.node--teaser-alt .teaser__img1 {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
}
.teaser.node--teaser-alt .teaser__img1:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../assets/img/a3.webp);
    background-size: cover;
    transition: all 0.3s ease-in-out;
    background-position: top;
}
.teaser.node--teaser-alt .teaser__img1:after {
    content: '';
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    opacity: 1;
    background: linear-gradient(180deg,transparent 0%,#000000 100%);
}
.teaser.node--teaser-alt .teaser__img1 .teaser--meta {
    position: relative;
}




.teaser.node--teaser-alt .teaser__img2 {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
}
.teaser.node--teaser-alt .teaser__img2:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../assets/img/a7.webp);
    background-size: cover;
    transition: all 0.3s ease-in-out;
    background-position: top;
}
.teaser.node--teaser-alt .teaser__img2:after {
    content: '';
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    opacity: 1;
    background: linear-gradient(180deg,transparent 0%,#000000 100%);
}
.teaser.node--teaser-alt .teaser__img2 .teaser--meta {
    position: relative;
}





.teaser.node--teaser-alt .teaser__img3 {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
}
.teaser.node--teaser-alt .teaser__img3:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../assets/img/a4.webp);
    background-size: cover;
    transition: all 0.3s ease-in-out;
    background-position: top;
}
.teaser.node--teaser-alt .teaser__img3:after {
    content: '';
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    opacity: 1;
    background: linear-gradient(180deg,transparent 0%,#000000 100%);
}
.teaser.node--teaser-alt .teaser__img3 .teaser--meta {
    position: relative;
}



.teaser.node--teaser-alt .teaser__img4 {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
}
.teaser.node--teaser-alt .teaser__img4:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../assets/img/a5.webp);
    background-size: cover;
    transition: all 0.3s ease-in-out;
    background-position: top;
}
.teaser.node--teaser-alt .teaser__img4:after {
    content: '';
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    opacity: 1;
    background: linear-gradient(180deg,transparent 0%,#000000 100%);
}
.teaser.node--teaser-alt .teaser__img4 .teaser--meta {
    position: relative;
}




.teaser.node--teaser-alt .teaser__img5 {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
}
.teaser.node--teaser-alt .teaser__img5:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../assets/img/a2.webp);
    background-size: cover;
    transition: all 0.3s ease-in-out;
    background-position: top;
}
.teaser.node--teaser-alt .teaser__img5:after {
    content: '';
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    opacity: 1;
    background: linear-gradient(180deg,transparent 0%,#000000 100%);
}
.teaser.node--teaser-alt .teaser__img5 .teaser--meta {
    position: relative;
}












.teaser.node--teaser-alt .teaser--meta {
    height: 100%;
    z-index: 1;
}
.block.block-nfts-across-uk .field--name-field-hubs .field__item .teaser--meta__content {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.teaser.node--teaser-alt .teaser--meta__content {
    padding: 1.0rem 3rem;
}
.teaser--meta__content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.block.block-nfts-across-uk .field--name-field-hubs .field__item .teaser--meta h2, .block.block-nfts-across-uk .field--name-field-hubs .field__item .teaser--meta main .block-course .field__label, main .block-course .block.block-nfts-across-uk .field--name-field-hubs .field__item .teaser--meta .field__label {
    font-size: 1.2rem;
    text-transform: uppercase;
    line-height: 24px;
    font-weight: 600;
}
.teaser.node--teaser-alt .teaser--meta h2, .teaser.node--teaser-alt .teaser--meta main .block-course .field__label, main .block-course .teaser.node--teaser-alt .teaser--meta .field__label {
    color: #FFFFFF;
    background-color: transparent;
    margin: 0;
    font-size: 2.8rem;
}
.teaser--meta h2, .teaser--meta main .block-course .field__label, main .block-course .teaser--meta .field__label {
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}
.block.block-nfts-across-uk .field--name-field-hubs .field__item .teaser--meta h2 span, .block.block-nfts-across-uk .field--name-field-hubs .field__item .teaser--meta main .block-course .field__label span, main .block-course .block.block-nfts-across-uk .field--name-field-hubs .field__item .teaser--meta .field__label span {
    color: #f9f9f9;
    display: block;
    font-size: 17px;
    font-weight: bold;
    text-align: left;
}
.teaser--meta__content > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-indent: -10000px;
    border: none !important;
    color: transparent;
    background-color: transparent;
    z-index: 3;
}
.teaser--meta h2, .teaser--meta main .block-course .field__label, main .block-course .teaser--meta .field__label, .teaser--meta p, .teaser--meta a {
    color: #fff;
    background-color: transparent !important;
}

section#section-16878425631230::before {
    content: "";
    position: absolute;
    top: 10px;
    
    width: 298px;
    height: 256px;
    z-index: 0;
    background-size: 298px;
  }
  
  section#section-16878425631230::after {
    content: "";
    position: absolute;
    bottom: 10px;
    width: 298px;
    height: 256px;
    z-index: 0;
    background-size: 298px;
    right: 0px;
  }















  .highlights-block {
    --margin-bottom: 80px;
    margin: 0 auto 80 auto;
    width: min(1280px,100%);
}
.highlights {
    --size: 180px;
}
.highlights {
    --size: 130px;
    --offset: calc(var(--size)*0.75);
    --shift: calc(var(--size)*0.21);
    position: relative;
}
.highlights .title {
    bottom: 300px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 2;
}
.section-title {
    font-family: var(--CircularBold),Helvetica,sans-serif;
    font-weight: 400;
    margin-block-end: 1em;
    text-transform: uppercase;
}
.section-title:before {
    border-left-color: #ffd40b;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-width: 7.5px 0 7.5px 12px;
}
.section-title:after {
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: #ffd40b;
    border-top-color: transparent;
    border-width: 7.5px 12px 7.5px 0;
}
.awds{
    background: #000;
}
.row-title h3 {
    font-size: 50px;
    letter-spacing: 0px;
    color: #ffffff;
    line-height: 0% !important;
}













.contentp p{
    color: #fff;
    z-index: 999;
    position: absolute;
}


.timeline {
    --color1: #ff5000;
    --color2: #ffae00;
    --color3: #8ac900;
    --color4: #00aeff;
    --color5: #953fbb;
    --color6: #ba3a17;
    --color7: #00a0b4;
    margin-left:12.5%;
    padding: 200px 0;
    font-family: 'Montserrat';
    display: inline-flex;
}
.timeline-item {
    position: relative;
    width:150px;
    height:30px;
    display:inline-table;
}

.timeline-item:nth-child(1) {
    width:75px;
}

.arrow-end {
    position: absolute;
    right: -14px;
    width: 0; 
    height: 0; 
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;  
}

.circle {
    position: absolute;
    background-position: center center;
    border-radius: 25px;
    transform: translate(-50%, -50%);
    top:50%;
    left: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 12px 0px #4d4d4d;
}

.timeline-item:nth-child(1) .circle {
    left: 0;
}

.inner-circle {
    position: absolute;
    transform: translate(-50%, -50%);
    top:50%;
    left: 50%;
    width:40px;
    height: 40px;
    border-radius: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size:30px 30px;
    background-color: #fff;
    box-shadow: inset 0px 0px 6px 0px #000;
}

.buble-content::after {
    content: '';
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    opacity: 0.7;
    background: linear-gradient(180deg,transparent 0%,#000000 100%);
}

.buble-content {
    overflow: hidden;
    text-align: center;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 12px 0px #4d4d4d;
    width: 290px;
    height: 170px;
    z-index: 9999;
}

.timeline-item:nth-child(odd) .buble-content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    top: -220px;
}

.timeline-item:nth-child(even) .buble-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    bottom: -220px;
}

.timeline-item:nth-child(1) .buble-content {
    left: -75px;
}

.content-year {
    padding: 5px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
}

.timeline-item:nth-child(even) .content-year-bottom  {
    position: absolute;
    width: 295px;
    bottom: 0;
    z-index: 9;
}
.awadstest h1{
        font-size: 50px;
        line-height: 1;
        color: #000;
        margin-top: 5px;
        margin-bottom: 20px;
        font-weight: 800;
        line-height: 1.4;
        word-break: break-word;
        text-transform: uppercase;
        letter-spacing: 0px;
        text-shadow: 0 0 BLACK;
        text-align: center;
        width: 100%;
}
.awadstest{
    margin: 3rem 0rem;
}
.contentp {
    margin: auto 0;
    padding: 5px;
    color: #000;
    font-size: 18px;
    height: 100%;
    display: block;
    position: relative;
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.contentp p {
    display: flex;
    height: 100%;
    margin: auto 0;
    font-size: 13px;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    align-items: center;
}

.content-half-circle {
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: #076a7f;
    width: 50px;
    height: 20px;
    z-index: 9999;
}

.timeline-item:nth-child(odd) .content-half-circle {    
    top: -40px;
    left: 50%;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    box-shadow: 1px 10px 9px 0px #bbbbbb;
}

.timeline-item:nth-child(even) .content-half-circle {
    bottom: -70px;
    left: 50%;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    box-shadow: -1px -9px 9px 0px #bbbbbb;
}

.timeline-item:nth-child(1) .content-half-circle {
    left: 0;
}

.grey-line {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 2px;
    height:80px;
    background-color: #b7b7b7;
}

.timeline-item:nth-child(odd) .grey-line {
    top:-30px;
    left: 50%;
}

.timeline-item:nth-child(even) .grey-line {
    bottom:-130px;
    left: 50%;
}

.timeline-item:nth-child(1) .grey-line {
    left: 0;
}


/* .awadstest::after {
    content: "";
    position: absolute;
    bottom: -95px;
    background-image: url("https://media.istockphoto.com/id/1413454966/vector/vector-illustration-of-man-give-woman-gift-box-with-present-for-birthday-valentine-day.jpg?s=612x612&w=0&k=20&c=nRzDKHlOoTz0PGIIw0EonikoLGJ4wj_JdowesCM6tuk=");
    width: 450px;
    height: 456px;
    z-index: 0;
    background-size: 458px;
    opacity: 0.1;
    right: 0px;
}
.awadstest::before {
    content: "";
    position: absolute;
    bottom: -275px;
    background-image: url("https://media.istockphoto.com/id/1302155443/vector/business-team-project-success-group-of-people-characters-holding-golden-goblet-or-cups.jpg?s=612x612&w=0&k=20&c=tSsheiniTQnT2ljiN-WQlNxsBgkrlbaelJPiMzGAcYU=");
    width: 450px;
    height: 456px;
    z-index: 0;
    background-size: 458px;
    opacity: 0.1;
    left: 0px;
    background-repeat: no-repeat;
} */
.ps-timeline-sec {
    position: relative;
  }
  .ps-timeline-sec .container {
    position: relative;
  }
  @media screen and (max-width: 767px) {
    .ps-timeline-sec .container ol:before {
      background: #348e80;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: 130px !important;
      left: 36px !important;
    }
    .ps-timeline-sec .container ol:after {
      background: #000;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: inherit !important;
      left: 36px;
      display: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline {
      margin: 0px 0 !important;
      border-left: 2px solid #000;      ;
      padding-left: 0 !important;
      padding-top: 40px !important;
      border-top: 0 !important;
      margin-left: 25px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li {
      height: 220px;
      float: none !important;
      width: inherit !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:nth-child(2) .img-handler-bot img {
      width: 100%;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child {
      margin: 0;
      bottom: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
      bottom: 40px !important;
      width: 40% !important;
      margin-left: 25px !important;
      margin-top: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot img {
      width: 100%;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-top {
      margin-bottom: 0 !important;
      top: 20px;
      width: 50% !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span {
      left: 0;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
      position: absolute !important;
      bottom: 150px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
      margin: 0 auto !important;
      width: 100% !important;
      object-position: center !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
        position: absolute !important;
        bottom: 115px !important;
        width: 90% !important;
        float: left !important;
        margin-left: 36px !important;
        margin-bottom: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li p {
      text-align: left !important;
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -40px;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -40px;
    }
    .ps-timeline-sec .container ol.ps-timeline li span{
        margin-left: 0px !important;
    }
  }
  /* .ps-timeline-sec .container ol:before {
    background: #000;
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 100%;
    position: absolute;
    left: -10px;
    top: -2px;
  }
  .ps-timeline-sec .container ol:after {
    background: #000;
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 100%;
    position: absolute;
    right: -10px;
    top:-3px;
  } */
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 300px 0;
    padding: 0;
    border-top: 2px solid #000;
    list-style: none;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    float: left;
    width: 14%;
    padding-top: 30px;
    position: relative;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    width: 60px;
    height: 60px;
    margin-left: -25px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 50%;
    box-shadow: 0 0 0 0px #fff;
    text-align: center;
    line-height: 50px -10;
    color: #000;
    font-size: 16px;
    font-style: normal;
    position: absolute;
    top: -35px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
}
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: "";
    color: #000;
    width: 2px;
    height: 50px;
    background: #000;
    position: absolute;
    top: -50px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    
    color: #000;
    width: 10px;
    height: 10px;
    background: #000;
    position: absolute;
    bottom: 100px;
    left: 44%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: "";
    color: #000;
    width: 2px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: -50px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    /* content: ""; */
    color: #000;
    width: 10px;
    height: 10px;
    background: #000;    ;
    position: absolute;
    top: 100px;
    left: 44%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 117px;
    width: 100%;
    z-index: 2;
    border:10px solid#fff;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    display: table;
    margin: 0 auto;
    width: 300px;
    border-radius: 18px;
    height: 150px;
    object-fit: cover;
    object-position: bottom;
    background:none;
    padding: 7px;
  }
 
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute;
    margin-top: 38px;
    width: 100%;
    z-index: 9;
    border: 10px solid #fff;
  }
 
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
    display: table;
    margin: 0 auto;
    width: 300px;
    border-radius: 18px;
    height: 150px;
    object-fit: cover;
    object-position: right;
    padding: 7px;
  }
 
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    position: absolute;
    margin-top: 35px;
  }
  .ps-timeline-sec .container ol.ps-timeline li span:hover{
    background :#ed1923;
    color:#fff;
    transform: scale(1.2, 1.2);
    top:-33px;
    z-index: -1;

  }
 
  
  .ps-timeline-sec .container ol.ps-timeline li:hover{
    transform: scale(1.09, 1.09);
  }
  @media only screen and (max-width : 1024px) {
    .ps-timeline-sec .container ol:before {
        background: #348e80;
        width: 0px;
        height: 0px;
        border-radius: 100%;
        position: absolute;
        top: 130px !important;
        left: 36px !important;
      }
    .ps-timeline-sec .container ol.ps-timeline {
        margin: 0px 0 !important;
        border-left: 2px solid #000;      ;
        padding-left: 0 !important;
        padding-top: 33px !important;
        border-top: 0 !important;
        margin-left: 25px !important;
      }
    .ps-sp-top{
        left: -30px !important;
    }
    .ps-sp-bot{
        left: -31px !important;
    }

  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute !important;
    bottom: 90px !important;
    width: 85% !important;
    float: left !important;
    margin-left: 36px !important;
    margin-bottom: 0 !important;
}
#chapter1 {
    height: auto !important;
}
.ps-timeline-sec .container ol.ps-timeline li {
  height: 220px;
  float: none !important;
  width: inherit !important;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute !important;
    bottom: 85px !important;
    width: 85% !important;
    float: left !important;
    margin-left: 36px !important;
    margin-bottom: 0 !important;
}
  }
  