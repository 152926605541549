.content-block {
    
    background-attachment: fixed;
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


.content-block7{
    background: #077187;
    background-attachment: fixed;
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 20px 0px 70px 0px;
}
.content-container {
    width: 80%;
    margin: 0 auto;
}
.mb60{
  margin-bottom: 4rem;
}
.big-title {
    flex-direction: row;
    font-size: 30px;
    transform: translate(0);
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
}
.big-title1 {
    flex-direction: row;
    font-size: 30px;
    transform: translate(0);
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
}
.CounterIcon {
  font-size: 40px;
  margin-right: 20px;
  display: flex;
  color: #ffffff;
}
.textCenter{
  text-align: center;
}
.numbers {
    display: flex;
    justify-content: space-around;
  }
  
  .number {
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
  
  .number .counter {
    font-weight: bold;
    font-style: italic;
    font-size: 1.9em;
    margin: 6px 0;
    color: #ffffff;
  }
  .number span {
    font-size: 1.2em;
    display: block;
    color: #ffffff;
  }
 .section-title7 {
    text-align: center;
    margin: 25px 0px 60px 0px;
}
.section-title7 .pre-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: .1em;
}
.section-title7 .title {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.4;
  word-break: break-word;
    color: #ffffff;
}

.counter-wrapper h2{
  width: 100%;
  display: flex;
  font-size: 28px;
}

.counter-wrapper{
  
  position: relative;
  margin-top: 0rem;
  background: #222;
}
.counter-wrapper1{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1.5rem;
  padding: 0rem 5% 1rem 5%;
  
}
/* .counter-wrapper::before{
  position: absolute;
  content: '';
  content: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  z-index: 1;
} */
.counter{
 text-align: center;   
 color: #ffffff;
 z-index: 2;
 position: relative;
}

.counter .count{
  font-size: 5rem;
  margin-bottom: 1rem;
  font-weight: 600; 
}
.counter p{
  font-size: 1.4rem;
  font-weight: 700;
}
.count img{
  width: 90px;
}
.counter-wrapper1 div{
  background: #222;
}
@media (max-width: 991px) {
  html{
      font-size: 55%;
  }
}
@media (max-width: 768px) {
 .counter-wrapper{
     grid-template-columns: repeat(2, 1fr);
     grid-row-gap: 8rem;
 }
}
@media (max-width: 450px) {
  html{
      font-size: 50%;
  }
  .counter-wrapper{
      grid-template-columns: 1fr;
  }
}
@media only screen and (max-width : 1024px) {
  .count img {
      width: 66px;
  }
  .counter-wrapper1 div {
      background: #222;
      width: 160px;
  }
  .counter-wrapper1{
      overflow:hidden;
  }
  
  .counter-wrapper1:hover{
      overflow: scroll !important;
  }
  .counter-wrapper1 {
      display: grid !important;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 1.5rem;
      padding: 0rem 5% 0rem 5%;
  }
  .mblcounter{
    display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }
  .cntr{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cntr img{
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  .counter .count {
    font-size: 40px !important;
    margin-bottom: 1rem;
    font-weight: 900;
}
}
